import { useState, useEffect } from "react";
import ApiServices from "../../utils/apiServices";
import TTSSidebar from "./TTSSidebar";
import BootstrapTable from 'react-bootstrap-table-next';
import { UploadIcon } from "../../assets";
import AllLexiFileTable, { AllLexiFile } from './AllLexiFileTable';
import AliasModal from "./AliasModal";
import CreateLexiModal from "./CreateLexiModal";

const TSSMyTask = () => {
    const [displayUoload,setDisplayUpload] = useState(false);
    const [newLexicon, setNewLexicon] = useState(false);
    const [modal , setModal] = useState(false);
    const [selectedLexiFile , setSelectedLexiFile] = useState({});
    const [refresh , setRefresh] = useState(false);
    useEffect(()=>{
        setModal(false);
        setRefresh(false);
    },[])
    console.log(selectedLexiFile[0]?.lexiconid)
    return (<>
        <div className="TTS-mytask-contents-wrapper" >
            <TTSSidebar />
            <div className="right-panel"  >
                <div className="WNFY-WOAV-RV-QH-outer-wrapper">
                    <div className="WNFY-WOAV-outer-wrapper">
                        <h1 className="intro-text">Manage Lexicon Files
                        </h1>
                        <div className="TSS-container-header">
                                        <div>
                                            <button className={(displayUoload) ? "TSS-button-style-selected" :
                                                "TSS-button-style-active"} onClick={() => setDisplayUpload(true)}
                                                onMouseLeave={() => setDisplayUpload(false)}
                                                //disabled={(selected.length <= 1) ? false : true}
                                                >
                                                <span
                                                    className="TSS-container-header-span">
                                                    <img src={UploadIcon} />
                                                    <span className="TSS-label-text"
                                                    >New</span>
                                                    {(displayUoload) ?
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="45.056"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} alt="" style={{ transform: "rotate(180deg)" }} />
                                                        :
                                                        <svg width="16px" height="14px" style={{ marginTop: "2px" }} viewBox="0 0 1024.00 1024.00" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#3276c3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#3276c3"></path></g></svg>
                                                        // <img src={arrowIconBlue} />
                                                    }
                                                </span>
                                                {(displayUoload) && <ul className="TSS-sub-wrapper-menu">
                                                    <li onClick={()=>setModal(true)}>Phoneme</li>
                                                    <li onClick={()=>setNewLexicon(true)}>Lexicon</li>
                                                </ul>}
                                            </button>
                                        </div>
                                        <div>
                                            <button className= "TSS-button-style-active">
                                                <span className="TSS-container-header-span">
                                                    <svg id="delete" xmlns="http://www.w3.org/2000/svg" width="11.274" height="16.156" viewBox="0 0 11.274 16.156" fill={"#0074b0"}>
                                                        <g id="Group_23297" data-name="Group 23297" transform="translate(0)">
                                                            <g id="Group_23296" data-name="Group 23296" transform="translate(0)">
                                                                <path id="Path_11289" data-name="Path 11289" d="M64,95a1.61,1.61,0,0,0,1.611,1.611h6.443A1.61,1.61,0,0,0,73.664,95V85.333H64Z" transform="translate(-63.195 -81.201)" fill="none" stroke={ "#0074b0" } stroke-width="1.5" />
                                                                <path id="Path_11290" data-name="Path 11290" d="M51.123.805,50.317,0H46.291l-.805.805H42.667V2.416H53.941V.805Z" transform="translate(-42.667)" fill= "#0074b0"  />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                    <span className="TSS-label-text"
                                                        onClick={()=>{}}
                                                    >Delete</span>
                                                </span>
                                            </button>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <div className="main-content-container" style={{ padding: "10px", width: "75%" }}>
                            <AllLexiFileTable
                            setSelectedLexiFile = {setSelectedLexiFile}
                            refresh = {refresh}
                            setRefresh = {setRefresh} />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {(modal) && 
            <AliasModal
            textselected = {selectedLexiFile[0]?.lexiconid}
            handleClose = {()=>{setModal(false)
                setRefresh(true)
            }}
            fromLexicon = {true}
            setAddLexiModal = {()=>{}}
            setPhonemeValue = {()=>{}}
            setIsPhoneme = {()=>{}}
            />}
            {(newLexicon) && <CreateLexiModal
            showModal = {newLexicon}
            handleClose={()=>setNewLexicon(false)}/>}
        </>)
}
        export default TSSMyTask;