import { useState, useEffect, useRef } from "react";
import ApiServices from "../../utils/apiServices";
import TTSSidebar from "./TTSSidebar";
import BootstrapTable from 'react-bootstrap-table-next';
import { EditorPen,PlayButton,PauseButton
} from "../../assets";
import axios from "axios";
import { error } from "jquery";
const AllLexiFileTable = (props) => {
   
    let MapValue = useRef (new Map());
    
    const [alldata, setAllData] = useState([]);
    const [isPlaying , setIsPlaying] = useState(false);
    const [checkedSelect, setCheckedSelect] = useState([]);
    const [row, setRowSelect] = useState({});
    const [checked, setCheck] = useState(false);
    const [isPlayingValue , setIsPlayingValue] = useState();
    
    useEffect(()=>{
        handleSelectRow ( row , checked );
       },[row,checked])
    
       useEffect(()=>{
        props.setSelectedLexiFile(checkedSelect);
       },[checkedSelect])

       useEffect(()=>{
        fetchData()
       },[props.refresh])

       useEffect(() => {
        fetchData()
    }, [])

       const handleSelectRow = (row, isSelect) => {
        if (isSelect) {
            if(row.docid) {
              setCheckedSelect ([...checkedSelect, row]);
            }
            else {  
              setCheckedSelect ([...checkedSelect, row])}
            }
       else {
        if(row.docid) {setCheckedSelect (checkedSelect.filter(x => x !== row))}
        else{setCheckedSelect (checkedSelect.filter(x => x !== row))}
            }
          }

    const fetchData = async () => {
        let res;
        await axios.get(`https://stage-ims.jnj.com/api/db/runquery?dbquery=select * from "iMS".phm_ims_ttslexiconphonemerel r INNER JOIN "iMS".phm_ims_ttsphoneme p ON p.id = r.phonemeid INNER JOIN "iMS".phm_ims_ttslexicon l ON l.id = r.lexiconid order by name asc`).then((response)=>
        res= response.data.rows).catch((error)=> console.log(error));
        console.log(res)
    
        /*let res = [
            {
                name: "Test",
                grapheme: "hello",
                phoneme: "helllooo",
            },
            {
                name: "Test1",
                grapheme: "hello1",
                phoneme: "helllooo1",
            },
            {
                name: "Test",
                grapheme: "hello2",
                phoneme: "helllooo2",
            },
        ]*/
        setAllData(res)
        res.map((val)=>{
            MapValue.current.set( val.name , false);
        })
        props.setRefresh(false)
    }
    console.log(MapValue)
    const handlePlayPause = (row) =>{
    setIsPlayingValue(row.name)
    }
    console.log(isPlayingValue)
    const handlePlay = async() =>{
        setIsPlaying(true)
    }
    const handlePause = () =>{
        setIsPlaying(false)
    }
    const handleEdit = (row) =>{
    }

    let columns = [
        {
            headerStyle: {
              paddingTop: '5px',
              width: "5%",
            },
            style: {
              padding:"1px"
            },
            hidden: false,
            dataField:"folderid",
            formatter: (cell,row)=>{
              return <div style={{marginTop: "10px"}}><input type="checkbox" id={row} name="row" value={row} onChange={(e)=>{
                setRowSelect(row);
                setCheck(e.target.checked);
                //props.setSelected(row);
              }}/></div>
            },
          },
          {
            dataField: 'grapheme',
            text: 'Grapheme',
            editable: true,
            headerStyle: {
                width: "20%"
            },
            style: {
                padding: "1px"
            },
        },
        {
            dataField: 'phoneme',
            text: 'Phoneme',
            editable: false,
            headerStyle: {
                width: '20%',
                height: "1%"
            },
            style: {
                padding: "1px"
            },
        },
        {
            dataField: 'alias',
            text: 'Alias',
            editable: false,
            headerStyle: {
                width: '20%',
                height: "1%"
            },
            style: {
                padding: "1px"
            },
        },
        {
            dataField: "name" ,
            text: ' Lexicon File Name',
            editable: false,
            headerStyle: {
              textAlign:"center",
              width:"20%",
              marginLeft:'10px',
            },
            style: {
               padding:"1px",
               width:'10%',
               textAlign:"center",
               marginLeft:'10px',
            },
          },
        {
            dataField: '',
            text: 'Play',
            editable: false,
            headerStyle: {
                    width: "10%"
            },
            style: {
                padding: "1px"
            },
            formatter: (cell , row) =>{
                
                // <div><button onClick={()=>handlePLayPause()}>{(isPlaying)?<img src={PauseButton}/>:<img src={PlayButton}/>}</button></div>
            if (isPlayingValue === row.name){
                return (<button onClick={()=>handlePlayPause(row)}><img src = {PauseButton}/></button>)
            }
            else{
                return (<><img src={PlayButton}></img></>)
            }
            
        }
    },
        {
            dataField: '',
            text: 'Edit',
            editable: false,
            headerStyle: {
                width:"10%"
            },
            style: {
                padding: "1px",
            },
            formatter: (row,cell)=>{
                return(
                    <button onClick={()=>handleEdit(row)}><img src = {EditorPen}/></button>
                )
            }
        }
    ]
    return (<>
        
            <div style={{ margin: '10px'}}>
            <div className="Table-CXonatainer"
                style={{
                    height: "60%",
                    marginTop: "5px",
                }}>

                <BootstrapTable
                className="tableElement"
                    keyField="name"
                    data={alldata}
                    columns={columns}
                    loading={true} />
            </div>
        </div>
        
    </>)
}
export default AllLexiFileTable;